import {
  Alert,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Flex,
  Heading,
  Input,
  useMediaQuery
} from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import { navigate } from 'gatsby'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import * as Yup from 'yup'

import { requestPasswordReset } from '../services/account'

const ForgottenPasswordPage = (props) => {
  const { location } = props
  const websiteContext = useContext(WebsiteContext)
  const [maxWidth768] = useMediaQuery('(max-width: 768px)')
  const [email, setEmail] = useState()
  const [errorToken, setErrorToken] = useState(location?.state?.errorToken)
  const [errorValidation, setErrorValidation] = useState(false)
  const [requestNewPassword, setRequestNewPassword] = useState(false)
  const shema = Yup.string().email('email adresse invalide').required('Ce champ est obligatoire')
  const handleSubmit = (event) => {
    event.preventDefault()
    shema.isValid(email).then(async function (valid) {
      if (valid) {
        setErrorValidation(false)
        try {
          await requestPasswordReset(email)
          setRequestNewPassword(true)
        } catch (e) {
          console.error(e)
        }
      } else {
        setErrorValidation(true)
      }
    })
  }
  const handleChange = (event) => {
    if (requestNewPassword) {
      setRequestNewPassword(!requestNewPassword)
    }
    setEmail(event.target.value)
  }

  return (
    <Box backgroundColor="#f6f3f1" w="100%">
      <Box maxW="1000px" ml="auto" mr="auto">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mot de passe oublié</title>
          <meta name="description" content="Mot de passe oublié" />
        </Helmet>

        <Heading
          as="h1"
          color="#333"
          fontSize={{ base: '2em', md: '3.75em' }}
          pt={{ base: '30px', md: '20px' }}
          pb={{ base: '30px', md: '20px' }}
          pr={{ base: '10px', md: '5px' }}
          pl={{ base: '10px', md: '5px' }}>
          Vous avez oublié votre mot de passe ?
        </Heading>
        <Box pb="80px">
          <Box p={{ base: '10px', md: '40px' }} bgColor={{ base: 'initial', md: '#fff' }}>
            {errorToken && (
              <Alert status="error" mb="20px">
                <AlertIcon />
                Votre lien de réinitialisation de mot de passe a expiré.
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={() => {
                    setErrorToken(false)
                  }}
                />
              </Alert>
            )}
            {requestNewPassword && (
              <Alert status="success" mb="20px">
                <AlertIcon />
                S&apos;il y a un compte associé à {email}, vous recevrez un courriel contenant un
                lien pour réinitialiser votre mot de passe.
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={() => {
                    setRequestNewPassword(false)
                  }}
                />
              </Alert>
            )}
            <h2
              style={{
                fontWeight: 'bold',
                fontSize: '1.5em',
                lineHeight: '1',
                marginBottom: '15px'
              }}>
              Réinitialiser votre mot de passe
            </h2>
            <p style={{ fontSize: '14px', lineHeight: '1.5', marginBottom: '15px' }}>
              Saisissez votre email ci-dessous et nous vous enverrons la marche à suivre pour
              réinitialiser votre mot de passe.
            </p>
            <form onSubmit={handleSubmit}>
              <Flex mb={{ base: '5px', md: '20px' }} flexDirection={{ base: 'column', md: 'row' }}>
                <label
                  htmlFor="email"
                  style={{
                    color: errorValidation ? '#ea412e' : '#999',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '1.071em',
                    width: maxWidth768 ? 'auto' : '25%',
                    marginBottom: '5px'
                  }}>
                  VOTRE ADRESSE EMAIL *
                </label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  width={{ base: 'auto', md: '35%' }}
                  onChange={handleChange}
                  borderColor={errorValidation ? '#ea412e' : '#c1c1c1'}
                  borderWidth={errorValidation ? '2px' : '1px'}
                  bg="white"
                />
              </Flex>
              <Flex justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }}>
                <Button
                  type="reset"
                  w={['100%', '100%', 'auto', 'auto']}
                  mt={{ base: '0px', md: '15px' }}
                  padding={{ base: '0', md: '5px 30px' }}
                  placeContent={{ base: 'start', md: 'initial' }}
                  bg={{ base: 'transparent', md: '#c1c1c1' }}
                  color={{ base: websiteContext?.mainColor, md: 'white' }}
                  _hover={{ backgroundColor: maxWidth768 ? 'transparent' : '#8d8e90' }}
                  borderRadius="0"
                  fontWeight={{ base: 'normal', md: '400' }}
                  fontSize={{ base: '14px', md: '1rem' }}
                  onClick={() => {
                    navigate('/')
                  }}>
                  Annuler
                </Button>
                <Button
                  type="submit"
                  w={['100%', '100%', 'auto', 'auto']}
                  mt={{ base: '0px', md: '15px' }}
                  padding="5px 30px"
                  bg={websiteContext?.darkColor}
                  color="#fff"
                  fontSize={{ base: '14px', md: '1rem' }}
                  fontWeight={{ base: 'normal', md: '400' }}
                  _hover={{ backgroundColor: websiteContext?.lightColor }}
                  borderRadius="0">
                  Envoyer
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

ForgottenPasswordPage.pageType = 'account'

export default ForgottenPasswordPage
